.monaco-hover {
  @apply rounded-lg;
  @apply !bg-product-background-primary-dark;
  @apply font-mimopro;
  @apply !border-2;
  @apply !border-product-line-primary-dark;
  @apply !text-product-text-primary-dark;
  @apply !p-1;
}

.monaco-hover .status-bar {
  display: none;
}

.monaco-hover .hover-contents {
  @apply !font-mimopro;
}

.monaco-editor .monaco-hover .hover-row:not(:first-child):not(:empty) {
  @apply !border-product-line-primary-dark;
}

/* NOTE: Required to keep left gutter over FTG/Validated input placeholder input fields on scroll */
.monaco-editor .margin {
  z-index: 20;
}

/* Storybook */

.sb-show-main {
  padding: 0 !important;
}

.customTooltip {
  border-radius: 8px !important;
  padding: 0 !important;
  margin-top: -3px !important;
}

/* default tooltip css styles */
.defaultTooltip {
  z-index: 51;
  border-radius: 0.5rem !important; /* 8px */
  padding: 0px !important;
  font-size: 0.75rem !important; /* 12px */
  line-height: 1rem !important; /* 16px */
  font-weight: 400 !important;
}

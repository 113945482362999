.lesson-fade-enter {
  opacity: 0;
}
.lesson-fade-exit {
  opacity: 1;
}
.lesson-fade-enter-active {
  opacity: 1;
}
.lesson-fade-exit-active {
  opacity: 0;
}
.lesson-fade-enter-active,
.lesson-fade-exit-active {
  transition: opacity 500ms;
}

@keyframes newConsoleLogHighlight {
  0% {
    @apply bg-transparent;
  }
  50% {
    @apply bg-product2-background-secondary;
  }
  100% {
    @apply bg-transparent;
  }
}

/* highlight newly added logs */
#console-container div[data-method='log'],
#console-container div[data-method='error'] {
  animation: newConsoleLogHighlight 1s ease-in-out;
}

/* add some padding and remove the bottom border of the last log */
#console-container > div > div:last-of-type,
#console-scroll-container > div > div:last-of-type {
  padding-bottom: 20px;
  border-bottom: 0;
}

/* NOTE: imho a very nice CSS solution to create a mobile fallback without relying on the navigator user agent. we're relying on pointer media query to figure out what kind of input we're supporting: 

- if the pointer is fine (mouse, trackpad, etc) we're assuming the user is on a desktop device and hide the fallback content and show the page content
- if the pointer is coarse (touchscreen) and the screen is wider than 1024px, we're assuming the user is on a tablet but is still able to have a good UX since the screen width is enough - we're hiding the fallback content and showing the page content
- in all other cases (e.g. the user is on a mobile [coarse], the user is on a tablet [coarse] with not enough screen width [<1024px]) we're showing the fallback content and hiding the page content

this of course is also not 100% foolproof but it's a good starting point to create a mobile fallback without relying on the user agent.
*/

.mobile-fallback__fallback-content {
  display: flex;
}

.mobile-fallback__page-content {
  display: none;
}

@media screen and (any-pointer: fine) {
  .mobile-fallback__fallback-content {
    display: none;
  }
  .mobile-fallback__page-content {
    display: flex;
  }
}

@media screen and (any-pointer: coarse) and (min-width: 1024px) {
  .mobile-fallback__fallback-content {
    display: none;
  }
  .mobile-fallback__page-content {
    display: flex;
  }
}

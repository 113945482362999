/* NOTE: CUSTOM MIMO FONTS */
@tailwind base;

@layer base {
  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-RegularItalic.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-MediumItalic.woff2) format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-BoldItalic.woff2) format('woff2');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: Aeonik Pro;
    src: url(../public/fonts/AeonikPro-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Aeonik Mono;
    src: url(../public/fonts/AeonikMono-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
}

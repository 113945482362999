.onboarding-slide-enter {
  opacity: 0 !important;
  transform: translateY(50px);
}
.onboarding-slide-exit {
  opacity: 1 !important;
  transform: translateY(0%);
}
.onboarding-slide-enter-active {
  opacity: 1 !important;
  transform: translateY(0%);
}
.onboarding-slide-exit-active {
  opacity: 0 !important;
  transform: translateY(-50px);
}
.onboarding-slide-enter-active,
.onboarding-slide-exit-active {
  transition:
    opacity 500ms,
    transform 500ms !important;
}

.onboarding-fade-enter {
  opacity: 0;
}
.onboarding-fade-exit {
  opacity: 1;
}
.onboarding-fade-enter-active {
  opacity: 1;
}
.onboarding-fade-exit-active {
  opacity: 0;
}
.onboarding-fade-enter-active,
.onboarding-fade-exit-active {
  transition: opacity 500ms;
}

.reload-helper {
  opacity: 0;
  animation: 0.2s linear fade-in 0.5s forwards;
}

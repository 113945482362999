#code-editor-tabs > div.last-of-row {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

#code-editor-tabs > div.last-of-row > button {
  flex-grow: 1;
  justify-content: space-between;
  border-right-width: 0;
}

.glossary-content h1 code {
  font-size: 32px;
}

.glossary-content h2 code {
  font-size: 28px;
}

.glossary-content h3 code {
  font-size: 22px;
}

.glossary-content p code {
  font-size: 18px;
}

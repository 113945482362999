@import './lesson-transition.css';
@import './onboarding-transition.css';
@import './modal-transition.css';
@import './react-split.css';
@import 'monaco.css';
@import './scrollbars.css';
@import './tooltip.css';
@import './prism/prism-mimo.css';
@import './console.css';
@import './textarea-auto-grow.css';
@import './builderio-fonts.css';
@import './blog.css';
@import './mobile-fallback-rules.css';
@import './code-editor-tabs.css';
@import './glossary.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

builder-dev-tools-menu {
  display: none;
}

/* NOTE: MARKDOWN COMPONENTS BASE STYLES */
@layer base {
  .markdown-ol-icon {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    list-style-image: url('/icons/checkmark.svg');
  }

  .markdown-ol,
  .markdown-ul {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 2rem;
    list-style: decimal;
  }

  .markdown-ol > li {
    @apply mt-2;
  }

  .tutor-hint-container > div > :first-child {
    @apply !mt-0;
  }

  li > .markdown-ol,
  li > .markdown-ul {
    @apply mt-0;
  }

  /* do not change - needed for standard lesson styling */
  pre > code {
    @apply !p-0;
  }

  /* used to have custom background color for codeblocks in projects and challenge instructions */
  pre.pre-codeblock > code {
    @apply !bg-product-background-code-dark;
  }

  li > p {
    margin-bottom: 0;
  }

  li > pre {
    margin-bottom: 16px;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .rotate-y {
    transform: rotateY(180deg);
  }

  .card {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
  }

  .perspective {
    perspective: 1000px;
  }

  .transform-preserve-3d {
    transform-style: preserve-3d;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }
}

html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family:
    AeonikPro-Regular,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

/* required to make browser show dark scrollbar */
html.dark {
  color-scheme: dark;
}

#__next {
  height: 100%;
}

input:checked + div {
  @apply border-primary-default-light;
}

input:checked + div svg {
  @apply block;
}

/* NOTE: we need to make sure to set body overflow according to what 
component is shown. We cannot rely on simply setting the element style
because other components would do the same and override it. So therefore
we create specific classes so that only the component that added the class
and hides the overflow can remove it. */

.navbar-body-overflow-hidden,
.modal-body-overflow-hidden,
.drawer-body-overflow-hidden {
  overflow: hidden;
}

/* xtermjs style overwrites */
.xterm-viewport {
  overflow: hidden !important;
}

.modal-fade-enter {
  opacity: 0;
}
.modal-fade-exit {
  opacity: 1;
}
.modal-fade-enter-active {
  opacity: 1;
}
.modal-fade-exit-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-exit-active {
  transition: opacity 300ms;
}

/* NOTE: shamelessly stolen from https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */

.mimo-textarea-grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.mimo-textarea-grow-wrap::after {
  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.mimo-textarea-grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  /* overflow: hidden; */
}
.mimo-textarea-grow-wrap > textarea,
.mimo-textarea-grow-wrap::after {
  /* Identical styling required!! */
  padding: 6px 20px 6px 12px;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

#mimo-blog-post-content img {
  margin-top: 64px;
  width: 100%;
  border-radius: 6px;
  /* notDesktop:margin-top: 36px; */
}

#mimo-blog-post-content a {
  text-decoration: underline;
  @apply text-product-accent-important-light;
}

#mimo-blog-post-content a:hover {
  @apply text-product2-background-accent;
}

#mimo-blog-post-content figure {
  margin: 16px 0 0 0;
}

#mimo-blog-post-content figure img {
  height: auto;
}

#mimo-blog-post-content figure figcaption {
  font-size: 14px;
  line-height: 38px;
  /* color: ${colors.textSecondary};
  notDesktop:line-height: 22px; */
}

#mimo-blog-post-content pre {
  font-size: 18px;
  line-height: 22px;
  margin-top: 64px;
  white-space: pre;
  border-radius: 6px;
  overflow: hidden;
  @apply bg-product-background-code-dark;
  /* notDesktop:margin-top: 36px; */
  /* phone:font-size: 16px;
  phone:line-height: 20px;
  phone:box-sizing: border-box;
  phone:overflow: auto; */
}

#mimo-blog-post-content pre code {
  @apply bg-transparent;
}

#mimo-blog-post-content p {
  margin-top: 12px;
}

#mimo-blog-post-content strong,
#mimo-blog-post-content h1,
#mimo-blog-post-content h2,
#mimo-blog-post-content h3,
#mimo-blog-post-content h4 {
  /* font-family: ${fonts.boldFont}; */
}

#mimo-blog-post-content h2,
#mimo-blog-post-content h3 {
  /* color: ${colors.textPrimary}; */
  margin-top: 64px;
  /* ipad:margin-top: 56px;
  phone:margin-top: 36px; */
}

#mimo-blog-post-content h2 {
  /* ipad:font-size: 26px;
  ipad:line-height: 36px; */
  font-size: 30px;
  font-weight: bold;
}

#mimo-blog-post-content h3 {
  /* ipad:font-size: 26px;
  ipad:line-height: 36px; */
  font-size: 24px;
  font-weight: bold;
}

#mimo-blog-post-content h4 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 32px;
}

#mimo-blog-post-content ol {
  list-style-type: decimal;
  padding-inline-start: 30px;
}
#mimo-blog-post-content ul {
  list-style-type: disc;
  padding-inline-start: 30px;
}

#mimo-blog-post-content blockquote {
  display: flex;
  margin: 64px 0;
  /* notDesktop:margin: 36px 0; */
}

#mimo-blog-post-content blockquote p {
  /* font-family: ${fonts.boldFontIt}; */
  font-style: italic;
  font-size: 32px;
  line-height: 44px;
  /* color: ${colors.night300}; */
  margin: 0;
  /* notDesktop:font-size: 26px;
  notDesktop:line-height: 40px; */
}

#mimo-blog-post-content blockquote cite {
  display: block;
  font-size: 16px;
  font-style: normal;
  line-height: 44px;
  /* color: ${colors.textSecondary}; */
  margin-top: 16px;
  /* notDesktop:margin-top: 24px; */
  /* phone:font-size: 14px;
  phone:line-height: 22px; */
}

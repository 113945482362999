code[class*='language-'],
pre[class*='language-'] {
  color: #f0f5fd;
  background: #191b33;
  font-family: var(--font-aeonikMono), Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  font-size: 14px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 22px;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  padding: 0.5rem 1rem;
}
code[class*='language-json'],
pre[class*='language-json'] {
  color: #fbab7b;
  background: #333661;
  font-family: var(--font-aeonikMono), Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  font-size: 14px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 22px;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  padding: 1rem 0;

  white-space: pre-wrap;
}

/* round the left corners of the first input element */
code[class*='language-'] + input:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* round the right corners of the last input element */
code[class*='language-'] + input:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* if there is a content before the input element and a gap after the input element, round the left corners */
code[class*='language-'][data-content='content']
  + input[data-next-element='gap'] {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* if there is a content before the input element and a content after the input element, round all corners */
code[class*='language-'][data-content='content']
  + input[data-next-element='content'] {
  border-radius: 6px;
  border-radius: 6px;
}

/* if there is a gap before the input element and a content after the input element, round the right corners */
code[class*='language-'][data-content='gap']
  + input[data-next-element='content'] {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.token.block-comment,
.token.prolog,
.token.cdata,
.token.comment {
  color: #6971a6;
}

.token.doctype,
.token.punctuation {
  color: #9798a9;
}

.token.tag,
.token.namespace,
.token.deleted {
  color: #96bcef;
}

.token.attr-name {
  color: #bfdaf9;
}

.token.char,
.token.regex,
.token.variable {
  color: #ffc4fb;
}

.token.attr-value,
.token.string {
  color: #fbab7b;
}

.token.boolean,
.token.number,
.token.function {
  color: #96bcef;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #96bcef;
}

.token.selector,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #fbab7b;
}

.token.operator,
.token.entity,
.token.url {
  color: #f284ef;
}

.token.inserted {
  color: green;
}

.token.important {
  color: #ffd011;
}

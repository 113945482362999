.split {
  display: flex;
  flex-direction: row;
  /* NOTE: required max-height, otherwise growing markdown images push the content out of bounds. (h-12 is the height of the navbar) */
  max-height: calc(100% - theme('spacing.12'));
}

.gutter {
  @apply bg-fog-50;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
}
